
const RequestApi = {
    // 请求地址,符合"/"结尾
     ReqUrl : "https://api.dqhxc.com/dqhxc/api/",
     MangeReqUrl : "https://api.dqhxc.com/njdqhxc/api/",
    // ReqUrl: "http://127.0.0.1:8881/dqhxc/api/",
    // 请求地址,符合"/"结尾
    // ReqUrl : "http://127.0.0.1:8880/dqhxc/api/",

    // 静态文件访问地址,符合"/"结尾
    StaticPath : "https:/static.dqhxc.com/",
    //StaticPath: "http://127.0.0.1/img/",
};


export default RequestApi;
