<template>
  <div style="background-color: #f7f7f7">
    <headers></headers>
    <div v-if="!enabled" class="main1200" style="height:781px;margin-bottom:0px">
      <p class="head_p">
        目前网络投票仅在微信小程序开放，扫描下方二维码进入小程序进行投票。
      </p>
      <div class="tp_count_div">
        <div class="buttom_div">微信扫一扫</div>
        <img class="tp_img" src="@/assets/img/new_tp.png" alt="">
      </div>
      
    </div>
    <div
      v-if="enabled"
      class="main1200"
      style="margin-bottom: 0; padding: 20px 30px 0"
    >
      <div class="appraise_hear">
        <img src="@/assets/zpzs.png" alt="" />
      </div>
    </div>
    <div v-if="enabled" class="main1230" :style="{ height: highr + 'px' }">
      <router-link to="/src/views/initialAudit.vue" target="_blank">
        <div class="rankings" :style="{ right: rights + 'px' }">
          <img src="@/assets/paihang-icon-@2x.png" alt="" srcset="" />排名
        </div>
      </router-link>
      <div style="width: 100%; height: 1px"></div>
      <div class="vote_hear">
        <div class="vote_hear_text">
          由省委宣传部、省住房城乡建设厅、省教育厅、团省委、省文联联合举办的“寻红色记忆
          绘田园新颜”第三届“丹青妙笔绘田园乡村”活动初评已结束，共有173幅（组）作品进入复评。现将提交绘画原件的160幅（组）作品予以展示并接受网络投票，网络投票结果将作为复评的重要参考依据，其中得票数前20名的作品原则上直接入围奖项角逐。本届活动最终获奖作品将在江苏省美术馆向社会公开展览。
        </div>
        <div class="vote_box_sj">
          · 投票时间：<span>2024年11月18日12:00-2024年11月25日12:00</span>
        </div>
        <div class="vote_box_sj" style="margin-top: 10px; margin-bottom: 20px">
          · 投票规则：<span>每天最多投20票，单幅作品最多投10票，可多选。</span>
        </div>
        <div class="vote_box_capsule">
          <div class="vote_box_capsule_box">
            <div class="vote_box_capsule_box_sum">0</div>
            <div class="vote_box_capsule_box_text">投票人次</div>
          </div>
          <div class="vote_box_capsule_fx"></div>
          <div class="vote_box_capsule_box">
            <div class="vote_box_capsule_box_sum">0</div>
            <div class="vote_box_capsule_box_text">总投票数</div>
          </div>
        </div>
        <el-input
          placeholder="请输入作品编号或作者姓名"
          suffix-icon="el-icon-search"
          v-model="input"
          class="vote_box_search"
          @input="inpunChange"
        >
        </el-input>
      </div>
      <div class="vote_mian">
        <div
          class="vote_mian_box"
          v-for="item in tableData"
          :key="item.code"
          @click="isShowsClick(item)"
        >
          <div class="vote_mian_box_img">
            <el-image
              style="width: 100%; height: 160px"
              :src="RequestApi.StaticPath + item.thumbnail"
              fit="contain"
            ></el-image>
          </div>
          <div class="vote_mian_box_pian">{{ item.code }}</div>
          <div
            :class="
              item.author.length < 5 ? 'vote_mian_box_ons' : 'vote_mian_box_on'
            "
          >
            {{ item.author }}
          </div>
          <div class="vote_mian_box_bts">得票数：{{ item.voteNum }}</div>
        </div>
        <div style="width: 100%; height: 20px; float: left"></div>
      </div>
    </div>
    <!-- 幕布 -->
    <div class="mub" v-if="isShows">
      <div class="mub_box">
        <div class="mub_box_im">
          <el-image
            style="width: 100%; height: 350px"
            :src="RequestApi.StaticPath + itemList.original"
            fit="contain"
          ></el-image>
        </div>
        <div class="mub_ern">
          作品编号：<span style="color: #3d3d3d">{{ itemList.code }}</span>
        </div>
        <div class="mub_ern">
          作者姓名：<span style="color: #3d3d3d">{{ itemList.author }}</span>
        </div>
        <div class="mub_ern">
          所在院校：<span style="color: #3d3d3d">{{ itemList.college }}</span>
        </div>
        <div class="mub_ern">
          作品名称：<span style="color: #3d3d3d">{{ itemList.title }}</span>
        </div>
        <img
          src="@/assets/close.png"
          class="mub_close"
          @click="isShows = false"
        />
      </div>
    </div>
    <footers></footers>
  </div>
</template>
<script>
import headers from "@/components/headers.vue";
import footers from "@/components/footers.vue";
import utilMd5 from "@/assets/md5.js";
import RequestApi from "@/api";

export default {
  name: "appraise",
  data() {
    return {
      // 是否开始
      enabled: false,
      RequestApi,
      tableData: [],
      input: "",
      highr: document.body.clientHeight - 215,
      isShows: false,
      itemList: {},
      rights: (document.body.clientWidth - 1200) / 2,
    };
  },
  components: {
    headers,
    footers,
  },

  mounted() {
    this.getCpVoteVoList();
    window.onresize = () => {
      return (() => {
        this.rights = (document.body.clientWidth - 1200) / 2;
      })();
    };
  },

  methods: {
    //刷新数据
    getCpVoteVoList() {
      var timestamp = Math.trunc(Date.now() / 1000);
      var stringSignTemp =
        "opo_N5M70YlBdMRpvCiNgtZij6qs" +
        timestamp +
        "a4f605d055834067b306967ebad3f396db4966ba39ba4d39a63f4a7e61d80914";
      this.$axios({
        url: "voteip/opus/datas",
        method: "get",
        params: {
          data: {
            openId: "opo_N5M70YlBdMRpvCiNgtZij6qs",
            page: 1,
            limit: 173,
            wordKey: this.input,
            sign: utilMd5.hexMD5(stringSignTemp).toUpperCase(),
            timestamp: timestamp,
          },
        },
      })
        .then((res) => {
          if (res.data.code == "200") {
            this.tableData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //搜索
    inpunChange(e) {
      console.log(e);
      this.getCpVoteVoList();
    },
    //
    isShowsClick(e) {
      this.isShows = true;
      this.itemList = e;
    },
  },
};
</script>
<style scoped>
.tp_count_div{
  display: flex;            /* 启用弹性布局 */
  flex-direction: column;   /* 设置子元素纵向排列 */
  align-items: center;   /* 在纵向（主轴）上居中对齐 */
}
.tp_img{
  width: 400px;
  height: 400px;
}
.head_p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #81baab;
  line-height: 26px;
  text-align: center;
  font-style: normal;
  padding-bottom: 15px;
  border-bottom: 1px solid #dcdcdc;
}
.buttom_div {
  width: 373px;
  height: 76px;
  background: #ebf2f0;
  border-radius: 38px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 36px;
  color: #81baab;
  line-height: 76px;
  text-align: center;
  font-style: normal;
  margin: 28px auto 48px;
}
.bottomb {
  width: 100%;
  height: 30px;
  background: #fff;
  line-height: 30px;
  text-align: center;
}
.appraise_hear {
  margin: auto;
  height: 65px;
  border-bottom: #efefef solid 1px;
  position: relative;
}
.appraise_hear img {
  width: 284px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15.5px;
  margin-left: -142px;
}
.main1230 {
  width: 1200px;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px !important;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
.main1230::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.rankings {
  width: 81px;
  height: 36px;
  position: fixed;
  top: 55%;
  background-color: rgba(129, 186, 171, 1);
  color: #fff;
  line-height: 36px;
  font-size: 14px;
  text-indent: 32px;
  border-radius: 50px 0px 0px 50px;
  cursor: pointer;
}

.rankings img {
  width: 15px;
  height: 12px;
  position: absolute;
  top: 12px;
  left: 11px;
}
.vote_hear {
  width: 80%;
  height: 400px;
  border-radius: 16px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
  margin: 24px auto;
}
.vote_mian {
  width: 80%;
  margin: 12px auto;
}
.vote_mian_box {
  width: 32%;
  height: 344px;
  display: inline-table;
  background-color: #fff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  float: left;
  margin-bottom: 24px;
}
.vote_mian_box:nth-child(3n-1) {
  margin: 0 2%;
}
.vote_mian_box_img {
  width: 100%;
  height: 160px;
  border-bottom: 2px solid rgba(222, 222, 222, 1);
  display: inline-block;
}
.vote_mian_box_pian {
  width: 100px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: #ebf2f0;
  font-size: 24px;
  font-weight: 700;
  color: #81baab;
  margin: 6px auto 0;
  border-radius: 8px;
}
.vote_mian_box_on {
  margin: 8px 20px;
  height: 62px;
  line-height: 28px;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
}
.vote_mian_box_ons {
  margin: 8px 20px;
  height: 62px;
  line-height: 62px;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
}
.vote_mian_box_bts {
  margin: 0px 14px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  border-radius: 8px;
  border: 2px solid #81baab;
  font-size: 20px;
  color: #81baab;
  overflow: hidden;
}
.vote_hear_text {
  text-indent: 2em;
  font-size: 18px;
  line-height: 30px;
  padding: 12px;
  margin: 18px 20px 0px;
  color: rgba(34, 34, 34, 1);
  text-align: justify;
}
.vote_box_sj {
  margin: 0px 32px 0;
  font-size: 18px;
  color: rgba(34, 34, 34, 1);
  height: 34px;
  line-height: 34px;
  font-weight: 700;
}
.vote_box_sj span {
  font-size: 16px;
  color: #4d4d4d;
  font-weight: 700;
}
.vote_box_capsule {
  width: 260px;
  height: 60px;
  background: #fefefe;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  margin: 0 auto 12px;
  position: relative;
}
.vote_box_capsule_box {
  width: 50%;
  height: 60px;
  display: inline-block;
  position: relative;
}
.vote_box_capsule_fx {
  width: 2px;
  height: 40px;
  background-color: rgba(222, 222, 222, 1);
  position: absolute;
  top: 10px;
  left: 130px;
}
.vote_box_capsule_box_sum {
  width: 100%;
  height: 22px;
  line-height: 22px;
  color: rgba(129, 186, 171, 1);
  font-size: 20px;
  text-align: center;
  position: absolute;
  top: 8px;
  left: 0;
  font-weight: 700;
}
.vote_box_capsule_box_text {
  width: 100%;
  height: 28px;
  line-height: 28px;
  color: rgba(34, 34, 34, 1);
  font-size: 16px;
  text-align: center;
  position: absolute;
  bottom: 3px;
  left: 0;
  font-weight: 700;
}
.vote_box_search {
  width: 380px;
  height: 40px;
  margin: 18px auto 0;
  background: #fefefe;
  /* box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.3); */
  border-radius: 40px;
  display: flex;
}
.vote_box_search_btn {
  width: 55px;
  height: 26px;
  background: #81baab;
  border-radius: 26px;
  line-height: 26px;
  margin: 5px 2px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  display: inline-block;
}
.tabss {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
.tabss::-webkit-scrollbar {
  display: none;
}
.el-table__body-wrapper::-webkit-scrollbar {
  width: 0;
  background: rgba(213, 215, 220, 0.3);
  border: none;
}
.el-table__body-wrapper::-webkit-scrollbar-track {
  border: none;
}
.el-table th.gutter {
  display: none;
  width: 0;
}
.el-table colgroup col[name="gutter"] {
  display: none;
  width: 0;
}

.el-table__body {
  width: 100% !important;
}

.mub {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}
.mub_box {
  width: 500px;
  height: 600px;
  background-color: #fff;
  position: absolute;
  top: 10%;
  left: 50%;
  margin-left: -250px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.mub_box_im {
  width: 96%;
  height: 350px;
  margin: 10px 2% 20px;
  border-bottom: 2px solid rgba(189, 189, 189, 1);
}
.mub_ern {
  width: 96%;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  margin: 0 auto 12px;
  color: #999999;
}
.mub_close {
  width: 36px;
  height: 36px;
  position: absolute;
  bottom: -60px;
  left: 50%;
  margin-left: -18px;
  cursor: pointer;
}
</style>
