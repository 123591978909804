<template>
  <div style="background-color: #f7f7f7">
    <div class="container2">
      <div class="pcbanner">
        <div class="ddh_div">
          <div class="ddh">
            <ul>
              <li v-for="(item,index) in headerData" :key="index" class="flex-direction" @click="NavClick(item)">
                <div class="coin_i">
                  <a >
                    <img  :src="item.icon"/>
                  </a>
                </div>
                <div class="zi_i text-white">
                  <a >{{ item.name }}</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="main1200 " style="margin-bottom: 0px;padding-bottom:0;">
      <div class="newstitle">
        <div class="newstitle_left left text-left" style="width: 60%">
          最新动态
        </div>
      </div>
      <div class="newscontent text-left ">
        <div id="content3" class="row">
          <div v-for="(item, index) in list" :key="index" class="col-sm-4">
            <router-link tag="div" target="_blank" to="">
              <div class="newslist " style="cursor:pointer;" @click="go(item.tourl)">
                <div class="left"><img :src="item.picurl" height="108" width="105.19">
                </div>
                <div class="newsimg_right" style="width: 170px !important;">
                  <div class="newsimg_title text-black" style="height: 80px;font-size: 17px !important;">{{
                      item.title
                    }}
                  </div>
                  <div class="text-grey text-sm " style="margin-top: 10px;">{{ item.addtime }}</div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="text-right">
          <div class="myPagination2">
            <el-pagination :page-size="pageNum" :total="num" layout="prev, pager, next"
                           @prev-click="tabData" @next-click="tabDataList" @current-change="tabClick">
            </el-pagination>
          </div>
        </div>

      </div>
    </div>
    <div class="main1200 " style="margin-bottom: 0px;margin-top:0;padding-top:0;">
      <div class="newstitle">
        <div class="newstitle_left left text-left" style="width: 60%">
          往届回顾
          <a v-for="(item, index) in PreviousBatchData"
             :class="item.batch === previousBatchSel ? 'news_history_title_selected':'news_history_title'"
             @click="ChangePreviousBatch(item.batch)">{{ item.name }}</a>
        </div>
      </div>
      <div class="newscontent text-left ">

        <div id="content" class="row">
          <div v-for="(item, index) in lists" :key="index" class="col-sm-4">
            <router-link tag="div" target="_blank" to="">
              <div class="newslist " style="cursor:pointer;" @click="go(item.tourl)">
                <div class="left"><img :src="item.picurl" height="108" width="105.19">
                </div>
                <div class="newsimg_right" style="width: 170px !important;">
                  <div class="newsimg_title text-black" style="height: 80px;font-size: 17px !important;">{{
                      item.title
                    }}
                  </div>
                  <div class="text-grey text-sm " style="margin-top: 10px;">{{ item.addtime }}</div>
                </div>
              </div>
            </router-link>
          </div>

        </div>
        <div class="text-right">
          <div class="myPagination">
            <el-pagination :page-size="pageNum" :total="nums" layout="prev, pager, next"
                           :current-page.sync="page2CurPage"
                           @prev-click="tabDatas" @next-click="tabDataLists" @current-change="tabClicks">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div v-show="deng" id="modal1653222159963" aria-labelledby="modalLabel" class="modal fade in"
         role="dialog" style="z-index: 2147483647; display: block; padding-right: 17px;">
      <div class="modal-dialog " style="width: 200px;">
        <div class="modal-content" style="border-radius: 20px;">
          <div class="modal-header" style="display: none;">
            <button class="close" data-dismiss="modal"
                    type="button"><span aria-hidden="true">×</span><span
                class="sr-only">Close</span></button>
            <h4 id="modalLabel" class="modal-title">提示</h4>
          </div>
          <div class="modal-body">
            <div class="text-xxl text-center margin-top"><img class="tshi" src="@/assets/img/tip.jpg"></div>
            <p class="text-xl text-center margin-top"
               style="color:#000000; text-align: center;text-indent: 0px;">{{dialogWarnMsg}}</p>
            <div class="text-xl text-center margin-top margin-bottom">
              <button
                  class="cu-btn round  bg-gradual-green btn ok" data-dismiss="modal"
                  style="background-image: linear-gradient(45deg, #72b588, #6e9c7d);border-radius: 16px;"
                  @click="masks">我知道了
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="deng" class="modal-backdrop fade in"></div>

    <div v-show="dengp" class="moder_opn">
      <img class="moder_opn_close" src="@/assets/img/close.png" @click="masktp">
      <div class="moder_opn_wen">网络投票</div>
      <div class="moder_opn_om">使用微信扫一扫</div>
      <img class="moder_opn_erw" src="@/assets/img/toup.png">
      <div class="moder_dom">扫描上方二维码<span style="color:rgba(61, 61, 61, 1)">进入小程序进行投票。</span></div>
    </div>
    <div v-show="dengp" class="modal-backdrop fade in"></div>
    <footers></footers>

  </div>
</template>
<script>

import footers from "@/components/footers.vue"
import coin1 from "@/assets/img/coin1.png";
import coin2 from "@/assets/img/coin2.png";
import coin3 from "@/assets/img/coin3.png";
import coin4 from "@/assets/img/coin4.png";
import coin5 from "@/assets/img/coin5.png";
import coin6 from "@/assets/img/coin6.png";
import coin7 from "@/assets/img/coin7.png";
import coin8 from "@/assets/img/coin8.png";

export default {
  data() {
    return {
      name: "aa",
      list: {},
      lists: {},
      num: 0,
      nums: 0,
      pageNum: 3,
      page2CurPage:1,
      deng: false,
      dengs: false,
      dengp: false,
      users: '',
      // 当前选择的批次
      previousBatchSel:1,
      // 往届回顾tab数据
      PreviousBatchData:[
        {
          name:"首届",
          batch:1
        },
        {
          name:"第二届",
          batch:2
        }
      ],
      dialogWarnMsg:"",
      // 菜单模块开启状态
      enableConfig: {
        // 0:关闭 1:开启
        home: 1,
        active: 1,
        create: 1,
        signUp: 1,
        appraise: 0,
        vote: 0,
        results: 0,
        results_award: 0,
        award: 1
      },
      // 菜单数据
      headerData: [
        {
          name: "活动公告",
          routeName: "active",
          routeUrl: "/src/views/active.vue",
          icon:coin1,
          warnMsg: "活动公告暂未发布",
          blank:true  // target="_blank"
        }, {
          name: "创作对象",
          routeName: "create",
          routeUrl: "/src/views/create.vue",
          icon:coin2,
          blank:true  // target="_blank"
        }, {
          name: "点此报名",
          routeName: "signUp",
          routeUrl: "/src/views/signUp.vue",
          warnMsg: "报名暂未开始",
          warnMsg2: "报名已结束",
          icon:coin3,
          blank:true  // target="_blank"
        }, {
          name: "初评结果",
          routeName: "appraise",
          routeUrl: "/src/views/appraise.vue",
          warnMsg: "该功能暂未开放",
          icon:coin4,
          blank:false  // target="_blank"
        }, {
          name: "网络投票",
          routeName: "vote",
          routeUrl: "/src/views/vote.vue",
          warnMsg: "投票暂未开放",
          icon:coin5,
          blank:false  // target="_blank"

        }, {
          name: "复评结果",
          routeName: "results",
          routeUrl: "/src/views/results.vue",
          warnMsg: "该功能暂未开放",
          icon:coin6,
          blank:false  // target="_blank"
        }, {
          name: "获奖名单",
          routeName: "results_award",
          routeUrl: "/src/views/results_award.vue",
          warnMsg: "该功能暂未开放",
          icon:coin7,
          blank:false  // target="_blank"
        }, {
          name: "线上展厅",
          routeName: "award",
          routeUrl: "/src/views/award.vue",
          icon:coin8,
          blank:false  // target="_blank"
        }]
    }
  },
  created() {

    this.$axios({
      url: "bm/getSysConfig",
      method: "get",
      params: {
        data:"",
      },
    }).then((res) => {
      if (res.data.code == "200") {
        let conifgData = res.data.data;

        // 报名开关
        let showBm = conifgData.isShowBm;
        if (showBm === 0) {
          this.enableConfig.signUp = 1
        } else if (showBm === 1) {
          this.enableConfig.signUp = 0
        } else if (showBm === 2) {
          this.enableConfig.signUp = 2
        }

        // 初评开启开关
        let showCp = conifgData.isShowCp;
        if (showCp === 0) {
          this.enableConfig.appraise = 1
        } else if (showCp === 1) {
          this.enableConfig.appraise = 0
        }

        // 网络投票 开启开关
        this.enableConfig.vote = conifgData.isShowWltp;
        // 复评结果 显示开关
        this.enableConfig.results = conifgData.isShowFp;

        // 获奖名单 显示开关
        let showHjmd = conifgData.isShowHjmd;
        if (showHjmd === 0) {
          this.enableConfig.results_award = 1
        } else if (showHjmd === 1) {
          this.enableConfig.results_award = 0
        }

        // 线上展厅 显示开关
        this.enableConfig.award = conifgData.isShowVr;
      }
    });

    this.checkUserExist()
  },
  mounted() {
    // 初始数据
    this.getData(1)
    this.getDatas(1)
  },
  methods: {
    NavClick(item){
      let status = this.enableConfig[item.routeName];
      if (status === 1) {
        if(item.name == "点此报名"){
          this.chooseNav(item)
          return
        }
        this.$router.push({path: item.routeUrl})
      }
       else if (status === 0) {
        if (item.warnMsg) {
          this.dialogWarnMsg = item.warnMsg;
          this.deng = true
        }
      } else if (status === 2) {
        if (item.warnMsg2) {
          this.dialogWarnMsg = item.warnMsg2;
          this.deng = true
        }
      }
    },
    chooseNav(item){
      const loginUser = sessionStorage.getItem("user");
      if(!loginUser || loginUser == ""){
        this.$router.push({
          path:item.routeUrl
        })
      }else{
        this.$axios({
                url: "signup/apply/Detail",
                method: "get",
                params: {
                  user: loginUser,
                },
              }).then((res) => {
                console.log(res);
                if (res.data.code == "200") {
                  let showTable = false;
                  if (res.data.data && res.data.data.info == null) {
                    sessionStorage.setItem("queryStatus", "0");
                    setTimeout(() => {
                      this.$router.push({
                        path: "/src/views/reDetails.vue",
                      });
                    }, 500);
                    return
                  }
                  if (res.data.data && res.data.data.info) {
                    if (res.data.data.info.tijiao > 1) {
                      showTable = true;
                    }
                  }

                  if (showTable) {
                    this.$router.push({
                      path: "/src/views/entryForm.vue",
                    });
                  } else {
                    if (res.data.data.info.tijiao == 1) {
                      sessionStorage.setItem("queryStatus", "1");
                      setTimeout(() => {
                        this.$router.push({
                          path: "/src/views/reDetails.vue"
                        });
                      }, 500);
                      return
                    }

                  }
                } else {
                  this.msgs = res.data.msg;
                  this.deng = true;
                }
              });
      }

    },
    ChangePreviousBatch(batch){
      this.previousBatchSel = batch;
      this.getDatas(1)
    },
    mask() {
      this.deng = true
    },
    masks() {
      this.deng = false
    },
    maskp() {
      this.dengs = true
    },
    maskps() {
      this.dengs = false
    },
    maskt() {
      this.dengp = true
    },
    masktp() {
      this.dengp = false
    },
    // 调用接口，获取初始数据，传参
    getData(e) {
      this.$axios({
        url: "newslist/list",
        method: "get",
        params: {
          data: {"newTypeId": 1, platform: 1},
          page: e,
          limit: 3
        }
      })
          .then((res) => {
            if (res.data.code == "200") {
              this.list = res.data.data.list
              this.num = res.data.data.total

            }
          })

    },
    getDatas(e) {
      this.$axios({
        url: "newslist/list",
        method: "get",
        params: {
          data: {"newTypeId": 2, platform: 1,previousBatch:this.previousBatchSel},
          page: e,
          limit: 3
        }
      })
          .then((arr) => {
            this.page2CurPage = e;
            if (arr.data.code == "200") {
              this.lists = arr.data.data.list
              this.nums = arr.data.data.total
            }
          })
    },
    checkUserExist() {
      if (sessionStorage.getItem('user') == null) {
        this.users = null
      } else {
        this.$axios({
          url: "bm/checkUserExist",
          method: "get",
          params: {
            data: {"user": sessionStorage.getItem('user')},
          }
        })
            .then((res) => {
              if (res.data.code == "200") {
                if (res.data.data == null) {
                  sessionStorage.clear()
                  this.users = null
                } else {
                  console.log(111)
                  this.users = sessionStorage.getItem('user')
                }

              }
            })
      }


    },
    // 切换
    tabData(e) {
      this.getData(e)
    },
    tabDataList(e) {
      this.getData(e)
    },
    tabClick(e) {
      this.getData(e)
    },
    // 切换2
    tabDatas(e) {
      this.getDatas(e)
    },
    tabDataLists(e) {
      this.getDatas(e)
    },
    tabClicks(e) {
      this.getDatas(e)
    },
    go(e) {
      // window.location.href = e
      window.open(e, '_blank')
    }
  },
  components: {
    footers
  }
}
</script>
<style scoped>
@import "@/assets/main.css";
@import "@/assets/style.css";
@import "@/assets/bootstrap.css";
@import "@/assets/golbal.css";
@import "@/assets/bootstrap.min.css";
@import "@/assets/app2.css";

.moder_opn {
  width: 542px;
  height: 548px;
  background: #FEFEFE;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.32);
  position: absolute;
  top: 86px;
  left: 50%;
  margin-left: -271px;
  z-index: 10000;
}

.moder_opn_wen {
  width: 130px;
  height: 45px;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #81BAAB;
  line-height: 45px;
  position: absolute;
  top: 20px;
  left: 206px;
}

.moder_opn_close {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 27px;
  right: 29px;
  cursor: pointer;
}

.moder_opn_om {
  width: 215px;
  height: 40px;
  line-height: 40px;
  background: #EBF2F0;
  border-radius: 38px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #81BAAB;
  position: absolute;
  top: 89px;
  left: 50%;
  margin-left: -107.5px;
  text-align: center;
}

.moder_opn_erw {
  width: 274px;
  height: 274px;
  position: absolute;
  top: 151px;
  left: 50%;
  margin-left: -137px;
}

.moder_dom {
  width: 408px;
  height: 32px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #81BAAB;
  line-height: 32px;
  position: absolute;
  top: 447px;
  left: 50%;
  margin-left: -204px;
}

.news_history_title, .news_history_title_selected{
  display: inline-block;
  font-size: 16px;
  border-radius: 6px;
  color: #81BAAB;
  padding: 2px 15px;

  margin-left: 36px;
  text-align: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
}

.news_history_title_selected {
  background: #81BAAB;
  color: #FFFFFF;
}

</style>
